// btn
.btn {
	box-shadow: $shadow;
	font-weight: 600;
	border-radius: 35px;
	padding: 0.5rem 2rem;
	font-size: 18px;
	display: flex;
	align-items: center;
	max-width: max-content;
	&::after {
		content: "\f0a9";
		margin-left: 0.6rem;
		font-family: $font-awesome;
		font-weight: 200;
		transition: 0.35s ease;
	}
	&:hover {
		&::after {
			transform: translateX(5px);
			transition: 0.35s ease;
		}
	}

	// btn-primary
	&.btn-primary {
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;
	text-decoration: none;
	font-weight: 600;
	&:hover {
		&::before {
			left: -5px;
			transition: 0.35s ease;
		}
	}

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
		transition: 0.35s ease;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

// book-btn
.book-btn {
	@extend.btn;
	background: $yellow-dark;

	&::after {
		display: none;
	}
	&:hover {
		background: linear-gradient(to top, $yellow-dark, $yellow-light);
	}
}
