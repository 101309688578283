// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
	@include media-breakpoint-down(md) {
		padding-left: 1.2rem;
		padding-right: 1.2rem;
	}
}
.container-fluid {
	@include media-breakpoint-up(xl) {
		padding: 0 2rem;
	}
	@include media-breakpoint-down(md) {
		padding: 0 15px;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
}

// default-card
.default-card {
	box-shadow: 0px 0px 20px #3a3a3a18;
	border-radius: 10px;
	border: none;
	background: $yellow-dark;
	.card-body {
		padding: 1.5rem;
		.card-title {
			font-size: 24px;
			font-weight: bold;
			font-family: $font-family-primary;
		}
	}
	.card-buttons {
		a,
		.card-btn {
			background: none;
			border: none;
			padding: 0;
			color: $purple;
			font-size: 18px;
			font-weight: bold;
		}
	}
	.card-image-caption {
		bottom: 0;
		min-width: 50%;
		width: 50%;
		background: rgba($green, 0.65);
		border-radius: 0 50px 0 0;
		.card-image-label {
			font-weight: 600;
		}
	}
}

// images

.gallery-img,
.gallery-link {
	border-radius: 12px;
}

.gallery-label {
	padding: 0.75rem 1.25rem;
	border-radius: 0 0px 50px 0;
}

*::selection {
	background: $primary;
	color: $yellow-dark;
}

// slider

.slider {
	.card {
		@extend .default-card;
		background: $body-bg;
		box-shadow: none;

		.card-title {
			font-size: 20px;
		}

		.card-body {
			padding: 1rem;
		}
	}
}
