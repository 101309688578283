.faq-item {
	background: $yellow-light;
	.faq-question {
		display: flex;
		align-items: center;
		box-shadow: 5px 5px 5px #00000029;
		border-radius: 4px;
		.faq-icon,
		.faq-title {
			i::before {
				color: $purple;
				content: "\f055";
				font-weight: 200;
				transition: 0.35s ease;
			}
		}
		.faq-category {
			margin: 0;
			* {
				color: $purple !important;
				font-size: 18px !important;
				font-weight: bold !important;
				font-family: $font-family-primary !important;
			}
		}
	}
	.faq-answer {
		margin: 0 !important;
		padding: 0 50px !important;
	}
	&.active {
		.faq-icon {
			i::before {
				content: "\f056";
				transform: sclaeY(-1);
				transition: 0.35s ease;
			}
		}
	}
}

.faq-overview {
	.faq-title {
		background: $yellow-dark !important;
		i::before {
			color: $purple;
			content: "\f055";
			font-weight: 200;
			transition: 0.35s ease;
		}
	}
	.faq-items {
		border: 1px solid $yellow-dark;
		.faq-item {
			.faq-question {
				box-shadow: none;
				margin: 0 !important;
				.title * {
					font-size: $h6-font-size !important;
					font-family: $font-family-primary !important;
					font-weight: 600 !important;
				}
			}
			&:nth-child(even) .faq-question {
				background: rgba($yellow-dark, 0.5) !important;
			}
		}
	}
}
