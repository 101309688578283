// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home intro section

.home-intro-section {
	margin: 12vh auto 0 auto;
	z-index: 2;
	position: relative;
	@include media-breakpoint-down(md) {
		margin: 2vh auto 0 auto;
	}
	.container {
		margin: 1rem auto;
		.column {
			text-align: center;
			.card {
				> * {
					text-align: left;
				}
			}
		}
		.title {
			margin-top: 3vh;
			color: $black;
			* {
				color: $black;
			}
		}
		.description {
			margin: auto;
			max-width: 70%;
			font-size: 20px;
			text-align: center;
			display: flex;
			flex-flow: column;
			align-items: center;
			@include media-breakpoint-down(md) {
				max-width: 90%;
			}
		}
		&.grid-container {
			margin-bottom: -6vh;
			@include media-breakpoint-down(md) {
				margin-bottom: 6vh;
			}
			.card {
				@extend .default-card;
			}
		}
	}
}

// eyecatcher-content
.eyecatcher-content-section {
	position: relative;
	z-index: 1;
	margin: 0 auto 12vh auto;
	.owl-stage-outer {
		padding-bottom: 6vh;
		@include media-breakpoint-down(md) {

		}
	}
	.owl-nav,
	.owl-dots {
		display: none;
	}
	.owl-carousel,
	.owl-item,
	.item {
		height: 80vh;
		border-radius: 10px;

		padding: 0 !important;
		@include media-breakpoint-down(md) {
			height: 40vh;
		}
	}
	.owl-caption-holder {
		height: 100%;
		display: flex;
		align-items: flex-end;

		.owl-caption {
			background: $purple;
			margin-bottom: -6vh;
			max-width: 25vw;
			border-radius: 10px;
			padding: 2.75rem;
			text-align: center;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			.owl-title {
				font-size: 30px;
			}
			.owl-subtitle {
				font-size: 18px;
			}
			.owl-btn {
				font-size: 18px;
				font-weight: bold;
			}
			@include media-breakpoint-down(md) {


				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	&.two {
		.owl-caption {
			margin-left: auto;
		}
	}
}

// surroundings-section
.surroundings-section {
	margin: 0 auto 6vh auto;
	@include media-breakpoint-down(md) {

	}
	.container {
		.item {
			max-width: 100% !important;
			flex-basis: 100% !important;
			&:nth-child(even) {
				.card {
					display: flex;
					flex-flow: row-reverse !important;
				}
			}
			.card {
				background: $yellow-dark;
				box-shadow: $shadow-card;
				border-radius: 10px;
				border: none;
				.card-image {
					max-width: 55%;
					flex-basis: 55%;
				}
				.card-body {
					max-width: 45%;
					flex-basis: 45%;
					display: flex;
					flex-flow: column;
					justify-content: center;
					padding: 2rem;
					.card-caption {
						flex: unset !important;
						.card-title {
							font-weight: bold;
							font-size: 24px;
							font-family: $font-family-primary;
						}
						.card-subtitle {
							font-size: 16px;
						}
					}
					.card-buttons {
						.card-btn {
							@extend .btn-secondary;
						}
					}
				}
				@include media-breakpoint-down(md) {
					.card-image,
					.card-body {
						max-width: 100%;
						flex-basis: 100%;
					}
				}
			}
		}
	}
	&.two {
		.item {
			&:nth-child(even) {
				.card {
					display: flex;
					flex-flow: row !important;
					@include media-breakpoint-down(md) {
						flex-flow: wrap !important;
					}
				}
			}
			&:nth-child(odd) {
				.card {
					display: flex;
					flex-flow: row-reverse !important;
					@include media-breakpoint-down(md) {
						flex-flow: wrap !important;
					}
				}
			}
		}
	}
}
// usp-section
.usp-section {
	margin: 6vh auto;
	.container {
		&.intro {
			border-bottom: 1px solid $gray-dark;
			margin: 12vh auto 2.5rem auto;

			.title {
				font-size: 40px;
				text-transform: uppercase;
				color: $black;
			}
			.description {
				max-width: 60%;
			}
			@include media-breakpoint-down(md) {
				text-align: center;
				.description {
					max-width: 90%;
				}
			}
			@include media-breakpoint-down(md) {
				margin: 12vh 1.5rem 2.5rem 1.5rem !important;
				max-width: 90vw;
			}
		}
		&.items {
			.slider {
				.card {
					@extend .default-card;
					background: $body-bg;
					box-shadow: none;
					.card-title {
						font-size: 20px;
					}
					.card-body {
						padding: 1rem 0 !important;
					}
				}
				.owl-nav,
				.owl-dots {
					display: none;
				}
			}
			.read-more {
				margin-top: 1.5rem;
				a {
					@extend .btn, .btn-secondary;
				}
				@include media-breakpoint-down(md) {
					margin: 1.5rem auto;
				}
			}
		}
	}
}

// faq section
.faq-section {
	margin: 18vh 2rem 0 2rem;
	padding: 12vh 0 6vh 0;
	background: $yellow-dark;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: -10vh;
		left: 0;
		height: 20vh;
		width: 100%;
		background: url("/images/footer-wave.svg");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100%;
	}
	.title {
		color: $black;
		font-size: 30px !important;
		text-transform: uppercase;
	}
	.description {
		max-width: 70%;
	}
	.read-more {
		margin-top: 3vh;
		a {
			@extend .btn, .btn-secondary;
			@include media-breakpoint-down(md) {
				padding: 0.5rem 1.25rem !important;
				font-size: 14px !important;
			}
		}
	}
	@include media-breakpoint-down(md) {
		margin-top: 12vh;
		margin-right: 1rem;
		margin-left: 1rem;
		padding-top: 6vh;

		.description, .title{
			text-align: center;
			margin: auto;
		}
		&::before {
			background: url("/images/footer-wave-mobile.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: bottom;
			height: 12vh;
			top: -6vh;
			transform: scaleX(-1);
			pointer-events: none;
		}
	}
}
// lead-section
// =========================================================================
&.accommodation-detail .lead-section {
	margin-top: 12vh !important;
}
.lead-section {
	margin: 12vh 0 6vh 0;
	.subtitle * {
		font-size: $h2-font-size;
	}
	.title,
	.page-title {
		text-transform: uppercase;

		* {
			color: $green !important;
		}
	}
	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;

			@extend .list-unstyled;

			li {
				margin: 0;
				font-weight: 600;
				text-transform: uppercase;
				&::after {
					content: "|";
					margin: auto 0.5rem;
				}
				&:last-child() {
					&::after {
						display: none;
					}
				}
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
