.form {
	border: none;
}
form {
	input,
	textarea, select {
		border-radius: 10px !important;
		border: none !important;
		font-size: 18px !important;
		font-weight: 600 !important;
		background: $yellow-dark !important;
	}
	label {
		font-weight: 600 !important;
	}
}
