// mini-sab
.mini-sab {
	z-index: 999;
	.placeholder,
	.input {
		font-weight: bold !important;
	}

	.heading {
		font-family: $font-family-secondary;
		text-transform: uppercase;
		margin: 0 !important;
		color: $white;
		font-size: 30px;
		margin-bottom: 1rem !important;
	}
	.container {
		max-width: 100%;
		position: relative;
		@extend .d-flex;
		justify-content: flex-end;
		margin-top: -28vh;

		.container-holder {
			max-width: 30vw;
			min-width: 20vw;
			z-index: 999;
			position: relative;
			margin: 15px 4rem 0 auto;
			padding: 1.5rem 1rem;
			border-radius: 10px;
			background: $secondary;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: #fff;
		}
	}
	@include media-breakpoint-down(md) {
		.container {
			margin-top: 9vh;
			.container-holder {
				max-width: unset;
				width: 100%;
				margin: auto;
			}
		}
	}
	.vc-popover-content-wrapper {
		@include media-breakpoint-up(md) {
			left: unset !important;
			right: 0 !important;
		}
	}
}
