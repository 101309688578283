// footer
&.home {
	.footer {
		margin-top: 0 !important;
		.footer-main {
			&::before {
				display: none;
			}
		}
	}
}
.footer {
	margin-top: 12vh;
	.footer-main {
		position: relative;
		margin: 0 2rem 6vh 2rem;
		background: $yellow-dark;
		border-radius: 0 0 10px 10px;
		padding: 6vh 0;
		.column {
			@include media-breakpoint-down(md) {
				display: flex;
				flex-flow: column;
			}
		}
		.logo {
			position: absolute;
			right: 0;
			top: 0;
			img {
				width: 279px;
			}

			@include media-breakpoint-down(md) {
				position: relative;
				order: 1;
			}
		}
		.footer-text {
			&.last {
				@include media-breakpoint-down(md) {
					order: 2;
					margin-top: 3vh;
				}
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				text-transform: uppercase;
			}
			a {
				text-decoration: none;
				color: $purple;
				&:hover {
					text-decoration: underline;
					i.fa-regular {
						transform: scale(1.05);
						transition: 0.35s ease;
					}
				}
				i.fa-regular {
					font-family: $font-awesome;
					font-weight: 600;
					margin-left: 0.25rem;
					font-size: 14px;
					transition: 0.35s ease;
				}
			}
		}

		&::before {
			content: "";
			position: absolute;
			top: -10vh;
			left: 0;
			height: 20vh;
			width: 100%;
			background: url("/images/footer-wave.svg");
			background-repeat: no-repeat;
			background-size: 100%;
			pointer-events: none !important;
			background-position: center center;
			@include media-breakpoint-down(md) {
				background: url("/images/footer-wave-mobile.svg") !important;
				background-repeat: no-repeat !important;
				background-size: cover !important;
				background-position: bottom !important;
				height: 12vh !important;
				top: -6vh !important;
				transform: scaleX(-1) !important;
				pointer-events: none !important;
			}
		}
		div.footer-contact {
			margin: 1rem 0 2rem 0;
			ul {
				display: flex;
				gap: 1rem;
				li {
					display: flex;
					flex-flow: row-reverse;
					a {
						@extend .btn, .btn-secondary;
						gap: 0.5rem;
						flex-flow: row-reverse;
						&::after {
							display: none;
						}
						&:hover {
							i::before {
								font-weight: 600;
							}
						}
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			margin-right: 1rem;
			margin-left: 1rem;
		}
	}
	.footer-partners {
		ul.partners {
			display: flex;
			gap: 2rem;
			justify-content: center;
			li {
				span {
					display: none;
				}
				img {
					max-width: 140px;
					max-height: 50px;
				}
			}
		}
	}
	.footer-socket {
		padding-top: 12vh;
		padding-bottom: 1rem;

		.list {
			display: flex;
			justify-content: center;
			width: 100%;
			gap: 0.85rem;
			li {
				a {
					font-size: 18px;
					text-decoration: none;
					color: $black;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			@include media-breakpoint-down(md) {
				flex-flow: column;
				justify-content: center;
				align-items: center;
				gap: 0.35rem;
			}
		}
	}
}
