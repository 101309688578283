// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-nav,
	.owl-dots,
	.owl-subtitle,
	.owl-description,
	.owl-btn {
		display: none !important;
	}
	.owl-caption-holder {
		.owl-caption {
			.owl-title {
				font-size: 60px;
				text-align: center;
				@include media-breakpoint-down(md) {
					font-size: 28px;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		height: 40vh;
	}
}
section.eyecatcher {
	position: relative;
	padding: 0;
	max-width: calc(100% - 4rem);
	margin: auto;
	.container-fluid {
		padding: 0 !important;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		height: 20vh;
		width: calc(100% + 1px);
		background: url("/images/banner-wave.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		z-index: 997;
		pointer-events: none;
		transform: translate(-50%, 50%);
		// border: 1px solid red;
		// @include media-breakpoint-down(md) {
		// 	background: url("/images/banner-wave-mobile.svg");
		// 	background-size: 100%;
		// 	background-repeat: no-repeat;
		// 	background-position: bottom;
		// 	transform: translate(-50%, 50%);
		// 	border: 1px solid green;
		// }
	}

	@include media-breakpoint-down(md) {
		padding: 0 15px;
		margin-top: 3vh;
		.item {
			border-radius: 20px 20px 0 0;
		}
	}
}
section.eyecatcher.large {
	position: relative;
	padding: unset;
	@include media-breakpoint-down(md) {
		margin-top: 3vh;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 80vh;
	min-height: 54px;
	max-height: 1080px;
	border-radius: 20px 20px 0 0;
	@include media-breakpoint-down(md) {
		height: 40vh;
	}
}
