// header
@keyframes change-color {
	to {
		border-color: $green;
	}
}
.header {
	z-index: 1000;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $yellow-light;
	transition: 0.5s;
	animation: change-color linear;
	animation-timeline: scroll();
	border-bottom: 5px solid $yellow-light;
	@include media-breakpoint-down(md) {
		border-bottom: 2px solid $yellow-light;
	}
	&.sticky {
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle

	.menu-toggle {
		position: absolute;
		left: 0;
		top: 0;
		background: $purple;
		border-radius: 0 0 150px 0;
		width: 68px;
		aspect-ratio: 1/1;
		display: flex;
		align-items: center;
		justify-content: center;
		.navbar-toggler {
			transform: translate(-12%, -25%);
			color: $white;
			i {
				transition: 0.35s ease;
			}
			&[aria-expanded="true"] {
				i {
					transform: rotate(360deg);
					transition: 0.35s ease;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
		@include media-breakpoint-up(xl) {
			display: none;
		}
	}

	// logo
	.logo {
		width: 175px;
		margin: 20px 0px 20px 0;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			width: 112px;
			margin: 10px 0 10px 0;
			margin-left: calc(68px + 1rem);
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 1;
			.navbar-nav {
				padding: 3rem 1rem;

				.nav-item {
					border-bottom: 1px solid $yellow-dark;

					.nav-link {
						font-family: $font-family-primary;
						color: $purple;
						font-size: 18px;
						font-weight: 600;
					}

					&.active {
						.nav-link {
							color: $green !important;
							font-weight: 600;
						}
					}

					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;

						&::after,
						&::before {
							display: none;
						}

						.nav-item {
							border-bottom: 0;
						}

						.nav-link {
							font-size: 16px;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			.navbar-nav {
				display: flex;
				gap: 1.2rem;
				.nav-item {
					.nav-link {
						font-size: 16px;
						font-weight: 600;
					}
					&.active,
					&:hover {
						.nav-link {
							color: $green;
						}
					}
					.dropdown-menu.show {
						&::after,
						&::before {
							display: none;
						}
						border: 0;
						border-radius: 0 0 10px 10px;
						box-shadow: none;
						background: $yellow-light;
						min-width: 10vw;
						text-align: center;
						.nav-link {
							color: $purple;
							&:hover {
								color: $green;
							}
						}
					}
				}
			}
		}
	}

	// book button
	div.book-button {
		margin-left: auto;
		margin-right: 0.5rem;
		a {
			@extend .btn, .book-btn;
		}
		@include media-breakpoint-down(md) {
			a {
				font-size: 10px !important;
			}
		}
	}

	// site-switcher
	.site-switcher {
		@include media-breakpoint-down(md) {
			margin-left: auto;
			margin-right: 15px;
			margin-bottom: 15px;
		}
	}
}
